export const darkModeMixin = {
  data() {
    return {
      isDarkMode: false,
    };
  },
  methods: {
    toggleDarkMode() {
      // Your logic to toggle dark mode
      this.isDarkMode = !this.isDarkMode;
      document.body.classList.toggle("dark-mode", this.isDarkMode);
      // Update the stored value in local storage
      localStorage.setItem("darkMode", this.isDarkMode.toString());
    },
  },
  mounted() {
    // Retrieve dark mode preference from local storage
    const storedDarkMode = localStorage.getItem("darkMode");
    if (storedDarkMode) {
      const isDarkMode = storedDarkMode === "true"; // Convert to boolean
      if (isDarkMode !== this.isDarkMode) {
        this.toggleDarkMode(); // Trigger toggle if preferences differ
      }
    }
  },
};
